import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"src\":[{\"path\":\"../assets/fonts/exo/exo-v19-latin-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/exo/exo-v19-latin-600.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/exo/exo-v19-latin-700.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"exo\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/components/Hamburger/Hamburger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/components/Matomo/TrackPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/components/Popover/Popover.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/components/Slider/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/angle-right.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/file.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/point-hand.svg");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/icons/tooltip.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/Accordions/Accordions.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/streamfields/EventFinder/EventFinder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/ProductPresentation/ProductPresentation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/ReadMore/ReadMore.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/streamfields/Reviews/ReviewText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/industriemeister_sbh/frontend/src/streamfields/TargetGroup/TargetGroup.tsx");
;
import(/* webpackMode: "eager" */ "/opt/industriemeister_sbh/frontend/src/styles/style.scss");
